import { ChangeDetectorRef, Component, ElementRef, HostListener, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subscription } from 'rxjs';
import { ApiUrlHelper } from 'src/app/common/api-url-helper';
import { AuthService } from 'src/app/services/auth/auth.service';
import { CartService } from 'src/app/services/cart/cart.service';
import { CommonService } from 'src/app/services/common/common.service';
import { StorageService } from 'src/app/services/storage/storage.service';
import { LogoutComponent } from '../logout/logout.component';
import { HeaderLabelConstant } from 'src/app/constants/LabelMessages';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {

  navlinks: { link: string, active: string, txt: string }[];
  public focus: boolean = false;
  length: any;
  CustomerName: any;
  CustomerImage: any;
  CustomerId: any;
  LoginToken: any;
  facebookUrl: any;
  twitterUrl: any;
  instagramUrl: any;
  youtubeUrl: any;
  bannerImg: any;
  numero$: Observable<string>;
  showSpan: boolean = true;
  labelConstant: any;
  private cartSubscription: Subscription;
  @ViewChild('navbarSupportedContent') navbar: ElementRef;
  decryptCustomerId:any;

  constructor(private router: Router, private authService: AuthService, private storageService: StorageService,
    private api: ApiUrlHelper, private spinner: NgxSpinnerService, private service: CommonService, private cartService: CartService, private cdr: ChangeDetectorRef
    , private modalService: NgbModal , private renderer:Renderer2) { }

  ngOnInit() {
    this.labelConstant = HeaderLabelConstant;
    this.CustomerImage = this.storageService.getValue('userImage');
    this.CustomerName = this.storageService.getValue('userName');
    this.CustomerId = this.storageService.getValue('userId');
    if(this.CustomerId != null && this.CustomerId != '' && this.CustomerId != undefined && this.CustomerId != 'undefined'){
      this.decryptCustomerId = this.service.DecryptNew(this.CustomerId);
      this.CustomerId = this.decryptCustomerId;
    }
    this.LoginToken = this.storageService.getValue('authToken');
    if (this.CustomerId > 0) {
      this.getCustomerDataById();
    }
    this.getSocialMediaLinks();
    this.service.getBannerData().subscribe(value => {
      if (value) {
        this.bannerImg = value;
      }
      else {
        this.bannerImg = ''
      }
      this.numero$ = this.cartService.numero$;
    });

    this.service.getUserImage().subscribe(value => {
      if (value) {
        this.CustomerImage = value;
        localStorage.setItem('userImage', value);
      }
      else {
        this.CustomerImage = ''
        localStorage.setItem('userImage', '');
      }
    });
    this.updateNavBar();
  }

  ngOnDestroy() {
    if (this.cartSubscription) {
      this.cartSubscription.unsubscribe();
    }
  }

  updateNavBar() {
    let mobile = { link: '/login', active: 'active', txt: 'Mobile' };
    let laptop = { link: '/logout', active: 'active', txt: 'Laptop' };
    let tv = { link: '/tv', active: 'active', txt: 'Tv' };
    let cart = { link: '/cart', active: 'active', txt: 'MyCart' };
    this.length = this.authService.length;
    if (this.authService.startStatus)
      this.navlinks = [mobile, laptop, tv, cart];
    else
      this.navlinks = [mobile, laptop, tv,];
  }

  redirectMyAccount() {
    this.router.navigate(['/my-profile'])
  }

  logout() {
    const modalRef = this.modalService.open(LogoutComponent, { size: 'md', animation: true, centered: true });
    modalRef.result.then((result) => {
    }, (reason) => {
      if (reason) {
        this.service.setUserImage('');
        this.service.clearLocalStorage(true);
        this.LoginToken = null;
        this.router.navigate(['/']);
        this.cartService.updateNumero("0");
      }
    });
  }

  imageHandler() {
    this.CustomerImage = '../../../assets/img/default-customer-profile.svg';
  }

  getSocialMediaLinks() {
    let searchStr = "social media link"
    let api = this.api.apiUrl.SocialMediaLinks.GetSocialMediaLinks.replace('{Id}', searchStr);
    this.spinner.show();
    this.service.doGet(api).pipe().subscribe({
      next: (response) => {
        if (response.success) {
          this.spinner.hide();
          this.facebookUrl = response.data[0];
          this.instagramUrl = response.data[1];
          this.twitterUrl = response.data[2];
          this.youtubeUrl = response.data[3];
        }
      },
      error: (err) => {
        this.spinner.hide();
        console.log(err);
      }
    });
  }

  getCustomerDataById() {
    let api = this.api.apiUrl.MyAccount.GetCustomerById.replace('{Id}', this.CustomerId);
    this.service
      .doGet(api)
      .pipe()
      .subscribe({
        next: (response) => {
          if (response.success) {
            this.CustomerImage = response.data.images
              this.cartService.updateNumero(response.data.cartCount);
          }
        },
        error: (err) => {
          this.spinner.hide();
          console.log(err);
        }
      });
  }

  navigate(url) {
    window.open(url, '_blank')
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    if (!this.navbar.nativeElement?.contains(event.target)) {
      this.closeNavbarIfOpen();
    }
  }

  closeNavbarIfOpen() {
    if (this.navbar.nativeElement?.classList.contains('show')) {
      this.renderer.removeClass(this.navbar.nativeElement, 'show');
    }
  }
}