/// <reference types="@angular/localize" />

import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';


platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));

if (environment.envtype == 'development') {
  // Check if service workers are supported by the browser
  if ('serviceWorker' in navigator) {
    // Get all service worker registrations and unregister them
    navigator.serviceWorker.getRegistrations().then(function (registrations) {
      for (let registration of registrations) {
        registration.unregister().then(() => {
          console.log('Service worker unregistered in development mode.');
        });
      }
    });
  }
}