import { Component, ElementRef, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ApiUrlHelper } from 'src/app/common/api-url-helper';
import { CommonErrorMessages } from 'src/app/constants/ErrorMessages';
import { RegisterLabelConstants } from 'src/app/constants/LabelMessages';
import { CommonService } from 'src/app/services/common/common.service';
import { SharedService } from 'src/app/services/shared-service/shared.service';
@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})

export class RegistrationComponent implements OnInit {

  // Common Properties
  signUpForm: any;
  showNewPassword: boolean = false;
  showConfirmPassword: boolean = false;
  submitted: boolean = false;
  errorConstants: any;
  labelConstants: any;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private spinner: NgxSpinnerService,
    private toast: ToastrService,
    private api: ApiUrlHelper,
    private commonService: CommonService,
    private sharedService: SharedService,
    private elementRef: ElementRef
  ) { }

  ngOnInit() {
    this.sharedService.setData('Sign up');
    this.commonService.setBannerData('');
    const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#\$%^&*])[A-Za-z\d!@#\$%^&*]{8,16}$/;
    this.signUpForm = this.formBuilder.group({
      FirstName: ['', Validators.required],
      LastName: ['', Validators.required],
      Email: ['', [Validators.required, Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]],
      NewPassword: ['', [Validators.required, Validators.pattern(passwordPattern)]],
      ConfirmNewPassword: ['', [Validators.required]],
      HealthConcern:['']
    }, {
      validator: this.passwordMatchValidator
    });
    this.errorConstants = CommonErrorMessages;
    this.labelConstants = RegisterLabelConstants;
  }

  get signUpFormControls() {
    return this.signUpForm.controls;
  }

  passwordMatchValidator(formGroup: FormGroup) {
    return formGroup.get('NewPassword').value === formGroup.get('ConfirmNewPassword').value
      ? null : { 'mismatch': true };
  }

  togglePasswordVisibility(fieldName: string) {
    this[fieldName] = !this[fieldName];
  }

  signUpCustomer() {
    this.submitted = true;
    if (this.signUpForm.valid) {
      let SignUpModel = {
        CustomerId: 0,
        Firstname: this.signUpForm.value.FirstName,
        Lastname: this.signUpForm.value.LastName,
        PhoneNumber: "",
        Email: this.signUpForm.value.Email,
        CustomerAddress: "",
        CountryId: null,
        State: null,
        City: null,
        PostalCode: "",
        ImageName: "",
        Password: this.signUpForm.value.ConfirmNewPassword,
        HealthConcernNote: this.signUpForm.value.HealthConcern
      };
      let newRequestModel = {
        CustomerName: this.signUpForm.value.FirstName + ' ' + this.signUpForm.value.LastName,
        EmailId: this.signUpForm.value.Email
      }
      let api = this.api.apiUrl.Account.CustomerOtpVerify;
      this.spinner.show();
      this.commonService.doPost(api, newRequestModel).pipe().subscribe({
        next: (response) => {
          if (response.success) {
            localStorage.setItem('registerData', JSON.stringify(SignUpModel));
            localStorage.setItem('Otp', response.data);
            this.toast.success(response.message);
            this.router.navigate(['/verifyotp']);
            this.spinner.hide();
          }
          else {
            this.toast.error(response.message);
            this.spinner.hide();
          }
        },
        error: (err) => {
          this.spinner.hide();
        }
      })
    }
  }

  redirectToTop() {
    this.elementRef.nativeElement.ownerDocument.defaultView.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }
}