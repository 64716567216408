<div class="modal-header bg-white">
    <h4 class="text-start">{{labelConstant.Logout}}</h4>
    <button type="button" class="btn-close" aria-label="Close"><i class="fa-solid fa-xmark"
            (click)="activeModal.dismiss(false)"></i></button>
</div>
<div class="modal-body delete-box">
    <h3>{{labelConstant.areYouSureYouWantTo}} {{labelConstant.logout}}</h3>
    <div class="btn-group text-center d-flex mt-4">
        <a class="btn btn-primary w-50 mt-3" aria-label="Close" (click)="activeModal.dismiss(true)">
            {{labelConstant.Logout}}
        </a>
        <a class="btn btn-light w-50 mt-3 ms-2" (click)="activeModal.dismiss(false)">
            {{labelConstant.cancel}}
        </a>
    </div>
</div>